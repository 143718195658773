@import-normalize;
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800&display=swap&subset=cyrillic,cyrillic-ext,greek,greek-ext,latin-ext,vietnamese');

.trimble-search {
  margin: 0;
  font-family: "Open Sans", "helvetica neue", helvetica, arial, sans-serif !important;
  font-size: 1rem;
  line-height: 1.625rem;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.trimble-search .sui-layout-body {
  margin-top: 1em;
  margin-bottom: 1em;
  background-color: #FFFFFF !important;
  color: #363545 !important;
}

.trimble-search .sui-layout-body:after {
  content: none !important;
}

.trimble-search .sui-layout-body__inner {
  margin: 0;
}

.trimble-search .sui-search-error {
  height: 4rem;
  font-family: inherit;
  color: inherit;
}

/* SEARCH FIELD & AUTOCOMPLETE RESULTS BOX */

.trimble-search .search-field {
  padding: 2em;
}

.trimble-search .search-field .sui-search-box__autocomplete-container {
  font-family: inherit;
  border-radius: 0;
  border: 1px solid #363545;
  box-shadow: none;
  background-color: #F9F9FB;
  top: 102%;
  padding: 0.75rem 0;
}

.trimble-search .search-field .sui-search-box__autocomplete-container .sui-search-box__section-title {
  font-size: 1.25rem !important;
  line-height: 1.75rem !important;
  padding-bottom: 0.5rem;
  font-weight: bold;
  text-transform: none;
  letter-spacing: normal;
}

.trimble-search .search-field .sui-search-box__autocomplete-container .sui-search-box__results-list > li {
  border-radius: 0;
  overflow-wrap: break-word;
}

.trimble-search .search-field .sui-search-box__autocomplete-container .sui-search-box__results-list > li[aria-selected="true"] {
  background-color: #EAEAEF;
}

.trimble-search .search-field .sui-search-box__autocomplete-container .sui-search-box__results-list > li:hover {
  background-color: #EAEAEF;
}

.trimble-search .search-field .sui-search-box__autocomplete-container .sui-search-box__results-list > li:focus {
  border: none;
}

/* FONTS */

.trimble-search h1 {
  font-size: 2rem !important;
  line-height: 2.75rem !important;
  color: #363545;
  font-weight: 800 !important;
  margin: 0 !important;
}

.trimble-search h1 {
  font-size: 2rem !important;
  line-height: 2.75rem !important;
  color: #363545;
  font-weight: 800 !important;
  margin: 0 !important;
}

.trimble-search h2 {
  color: #363545;
  font-size: 1.75rem !important;
  line-height: 2.5rem !important;
  font-weight: 800 !important;
  margin: 0 !important;
}

.trimble-search h3 {
  color: #363545;
  font-size: 1.5rem !important;
  line-height: 2rem !important;
  font-weight: 800 !important;
  margin: 1em 0 !important;
}

.trimble-search h4 {
  font-family: "Open Sans", "helvetica neue", helvetica, arial, sans-serif !important;
  color: #363545 !important;
  font-size: 1.25rem !important;
  line-height: 1.75rem !important;
  font-weight: bold !important;
  text-transform: none !important;
  letter-spacing: normal !important;
  margin: 0 !important;
}

.trimble-search p, 
.trimble-search span, 
.trimble-search .rc-pagination-item, 
.trimble-search .rc-pagination-item a, 
.trimble-search .sui-select *, 
.trimble-search .sui-search-box * {
  font-family: "Open Sans", "helvetica neue", helvetica, arial, sans-serif !important;
  font-size: 1rem !important;
  font-weight: normal;
  line-height: 1.625rem !important;
  color: #363545 !important;
}

.trimble-search p.small-text {
  margin: 1em 0 !important;
}

.trimble-search .small-text {
  font-family: "Open Sans", "helvetica neue", helvetica, arial, sans-serif;
  font-size: 0.75rem !important;
  font-weight: 600;
  line-height: 1.25rem !important;
}

.trimble-search .result-header > h3 {
  margin: 0 !important;
}

.trimble-search a {
  color: #363545 !important;
  text-decoration: none !important;
  outline: none;
}

.trimble-search a:hover {
  color: #54526B !important;
}

.trimble-search .sui-result em {
  color: #363545 !important;
  font-weight: 700 !important;
}

.trimble-search em:after {
  background: none !important;
}

/* PAGE ELEMENTS */

.trimble-search ul {
  list-style: none;
  padding: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}

.trimble-search .centered {
  display: flex;
  margin-top: 2rem;
  justify-content: center;
}

/* spinner */

.trimble-search .searching-spinner {
  margin-top: 3rem;
}

.trimble-search .searching-spinner svg {
  background-position: center center;
	background-repeat: no-repeat;
	background-size: 50vmin;
}

.trimble-search .searching-spinner svg circle {
  animation: 4s a infinite linear, 3s o infinite linear;
}

@keyframes a {
  from { stroke-dasharray: 100 0; }
  50%, 25% { stroke-dasharray: 0 100; }
  to { stroke-dasharray: 100 0; }
}

@keyframes o {
  from { stroke-dashoffset: 75 }
  to { stroke-dashoffset: 375 }
}

/* input, button & line styles */

.trimble-search .sui-search-box,
.trimble-search .sui-select__control {
  height: 3rem;
}

.trimble-search .sui-search-box form {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: stretch;
  width: 100%;
}

.trimble-search input {
  border: 1px solid #363545 !important;
  border-radius: 0px !important;
}

.trimble-search .sui-search-box input {
  height: inherit;
  border-color: #176BA6 !important;
  background-color: #F9F9FB !important;
}

.trimble-search .sui-search-box input:focus {
  box-shadow: none;
}

.trimble-search .sui-search-box .button {
  background: #0D6AA8 0% 0% no-repeat padding-box !important;
  font-size: 0.75rem !important;
  margin: 0 !important;
  border: none !important;
  outline: 0;
}

.trimble-search .button:hover {
  box-shadow: none !important;
  background-color: #0A5282 !important;
}

.trimble-search .button:focus {
  background-color: #073A5C !important;
}

.trimble-search button {
  border: 0 !important;
  background-color: initial;
  border-radius: 0 !important;
  box-shadow: none !important;
  cursor: pointer;
}

.trimble-search button:focus {
  outline: none !important;
}

.trimble-search button.search-button {
  padding: 1em;
}

.trimble-search button.search-button svg {
  fill: #FFFFFF;
  height: 1.5rem;
  width: 1.5rem;
}

.trimble-search button.clear-filters, .trimble-search button.show-more-options {
  border: none;
  font: 600 1rem "Open Sans";
  line-height: 1.625rem;
  color: #0D6AA8;
  background-color: unset;
  cursor: pointer;
  display: flex;
  margin-top: 0.5rem;
  align-items: center;
}

.trimble-search button.clear-filters svg, 
.trimble-search button.sui-layout-sidebar-toggle svg,
.trimble-search button.show-more-options svg {
  margin-right: 0.5rem;
}

.trimble-search button.clear-filters svg {
  height: 2rem;
}

.trimble-search button.show-more-options {
  padding: 0;
}

.trimble-search button.show-more-options svg {
  height: 1rem;
  width: auto;
}


.trimble-search button.show-more-options svg line,
.trimble-search button.show-more-options svg circle {
  stroke-width: 3;
}

.trimble-search button.clear-filters:hover,
.trimble-search button.reset-search:hover,
.trimble-search button.show-more-options:hover {
  color: #0A5282;
}

.trimble-search button.clear-filters:hover path,
.trimble-search button.reset-search:hover path,
.trimble-search button.show-more-options:hover path {
  stroke: #0A5282;
}

.trimble-search button.clear-filters:focus,
.trimble-search button.reset-search:focus,
.trimble-search button.show-more-options:focus {
  color: #073A5C;
}

.trimble-search button.clear-filters:focus path,
.trimble-search button.reset-search:focus path,
.trimble-search button.show-more-options:focus path {
  stroke: #073A5C;
}

.trimble-search button.sui-layout-sidebar-toggle {
  display: none;
}

.trimble-search button.sui-layout-sidebar-toggle svg * {
  stroke: #FFFFFF; 
}

.trimble-search button.sui-layout-sidebar-toggle .filter-count {
  margin-left: 1rem;
  border-radius: 50%;
  height: 1.25rem;
  width: 1.25rem;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 8px #0000001A;
  opacity: 1;
  color: #363545;
  font-size: 0.75rem;
  line-height: 1.25rem;
}

.trimble-search hr {
  border: 0;
  border-bottom: 1px solid #363544 !important;
}

.trimble-search hr.line-between-results {
  border-top: none !important;
  border-bottom: 2px solid #D0E1ED !important;
}

/* RESPONSIVENESS */

@media (max-width: 800px) {
  .trimble-search .sui-layout-body {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: 100vw;
  }

  .trimble-search .sui-layout-main {
    margin-top: 3rem;
  }

  .trimble-search .sui-layout-body__inner {
    padding: 0 !important;
  }

  .trimble-search button.clear-filters {
    margin: 1rem 0 0 0;
    padding: 0;
  }
  
  .trimble-search button.sui-layout-sidebar-toggle {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: #0D6AA8;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 600;
    color: #FFFFFF;
  }

  .trimble-search button.sui-layout-sidebar-toggle svg {
    width: 1rem;
    height: 1rem;
  }

  .trimble-search button.sui-layout-sidebar-toggle:hover {
    background-color: #0A5282;
  }

  .trimble-search button.sui-layout-sidebar-toggle:focus {
    background-color: #073A5C;
  }
  
  .trimble-search button.sui-layout-sidebar-toggle.close-sidebar {
    display: block;
    background-color: #FFFFFF;
    margin: 0;
    margin-left: auto;
    width: fit-content;
    width: -moz-fit-content;
    width: auto;
  }
  
  .trimble-search button.sui-layout-sidebar-toggle.close-sidebar:hover {
    background-color: unset;
  }

  .trimble-search button.sui-layout-sidebar-toggle.close-sidebar:focus {
    background-color: unset;
  }

  .trimble-search button.sui-layout-sidebar-toggle.close-sidebar svg * {
    stroke: #363545;
    stroke-width: 2;
  }

  .trimble-search button.sui-layout-sidebar-toggle.close-sidebar:hover svg * {
    stroke: #54526B;
  }

  .trimble-search .centered {
    flex-direction: column;
    align-items: center;
  }
}