.trimble-search.in-online-panel .search-field {
  padding: 0.5rem;
}

.trimble-search.in-online-panel .sui-layout-main {
  padding: 0 0.5rem !important;
  margin-top: 1rem;
  width: 100%;
}

.trimble-search.in-online-panel .sui-layout-body__inner {
  display: block;
  padding: 0 !important;
}

.trimble-search.in-online-panel .results-header h1 {
  font-size: 1.5rem !important;
  line-height: 2.5rem !important;
}

.trimble-search.in-online-panel .result-header a {
  color: #0D6AA8 !important;
  font-size: 1rem !important;
  line-height: 1.625rem;
  font-weight: 700;
}

.trimble-search.in-online-panel .search-field {
  display: flex;
}

.trimble-search.in-online-panel .search-field form {
  flex-grow: 1;
}

.trimble-search.in-online-panel .search-field button.reset-search {
  margin-left: 0.5rem;
}

.trimble-search.in-online-panel .result-header a {
  color: #0D6AA8 !important;
}

.trimble-search.in-online-panel .result-header a:hover {
  color: #0A5282 !important;
}

.trimble-search.in-online-panel .result-header a:focus {
  color: #073A5C !important;
}

.trimble-search.in-online-panel .sui-layout-body {
  margin-top: 0.5rem !important;
}

.trimble-search.in-online-panel .result-list .sui-result {
  padding: 0.5rem 0 !important;
}

.trimble-search.in-online-panel .result-list .sui-result .result-header {
  margin-bottom: 0;
}

.trimble-search.in-online-panel .result-list .sui-result .result-footer {
  margin-top: 0;
}

.trimble-search.in-online-panel .result-list .sui-result + .sui-result {
  margin-top: 0;
}

.trimble-search.in-online-panel .result-list .sui-result .tag-list .result-tag {
  border: 1px solid #363545;
  background-color: #F9F9FB;
  color: #363545;
  cursor: auto;
  margin: 0.5em 0.5em 0 0;
}

@media (max-width: 800px) {
  .trimble-search.in-online-panel .sui-layout-body {
    width: inherit;
  }

  .trimble-search.in-online-panel .sui-layout-sidebar {
    margin: auto;
  }
}