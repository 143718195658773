.trimble-search .sui-layout-sidebar {
  background-color: #F3F3F7 !important;
  padding: 1.5rem !important; 
  height: fit-content;
  width: 33% !important;
}

.trimble-search .sui-layout-sidebar .search-field {
  padding: 0;
}

.trimble-search .sui-layout-sidebar .sui-sorting {
  margin-bottom: 1.5em;
}

.trimble-search .sui-layout-sidebar .sui-sorting .sui-select {
  font-family: "Open Sans", "helvetica neue", helvetica, arial, sans-serif !important;
  font-weight: 600 !important;
  color: #363545;
  cursor: pointer;
}

.trimble-search .sui-layout-sidebar .sui-sorting .sui-select .sui-select__control {
  border: 1px solid #363545 !important;
  border-radius: 0px !important;
  background-color: #F9F9FB !important;
}

.trimble-search .sui-layout-sidebar .sui-sorting .sui-select .sui-select__control:focus {
  outline: none;
}

.trimble-search .sui-layout-sidebar .sui-sorting .sui-select .sui-select__control--menu-is-open .sui-select__dropdown-indicator {
  transform: rotateX(0.5turn);
}

.trimble-search .sui-layout-sidebar .sui-sorting .sui-select .sui-select__menu {
  border: 1px solid #363545 !important;
  border-radius: 0px !important;
  background-color: #F9F9FB !important;
  margin: 1px 0;
  box-shadow: none;
}

.trimble-search .sui-layout-sidebar .sui-sorting .sui-select .sui-select__menu .sui-select__menu-list {
  padding: 0;
}

.trimble-search .sui-layout-sidebar .sui-sorting .sui-select .sui-select__menu .sui-select__option {
  background-color: #F9F9FB !important;
}

.trimble-search .sui-layout-sidebar .sui-sorting .sui-select .sui-select__menu .sui-select__option:hover,
.trimble-search .sui-layout-sidebar .sui-sorting .sui-select .sui-select__menu .sui-select__option--is-selected {
  background-color: #EAEAEF !important;
}

.trimble-search .sui-layout-sidebar .facet-list {
  margin-top: 1.5em;
}

.trimble-search .sui-layout-sidebar .facet-list .sub-facets {
  margin-top: 1.5em;
  margin-left: 0.4em;
  padding-left: 1.75em;
  border-left: 0.3rem solid #0D6AA8;
}

.trimble-search .sui-layout-sidebar .facet-list .sub-facets h4 {
  font-size: 1.1rem !important;
}

.trimble-search .sui-layout-sidebar .facet-list .sub-facets+.option {
  margin-top: 1.5em;
}

.trimble-search .sui-layout-sidebar .facet-list .facet-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}

.trimble-search .sui-layout-sidebar .facet-list .facet-title line,
.trimble-search .sui-layout-sidebar .facet-list .facet-title circle {
  stroke: #363545 !important;
}

.trimble-search .sui-layout-sidebar .facet-list .facet-title:hover h4 {
  color: #54526B !important;
}

.trimble-search .sui-layout-sidebar .facet-list .facet-title:hover line,
.trimble-search .sui-layout-sidebar .facet-list .facet-title:hover circle {
  stroke: #54526B !important;
}

.trimble-search .sui-layout-sidebar .facet-list button.show-more-options line,
.trimble-search .sui-layout-sidebar .facet-list button.show-more-options circle {
  stroke: #0D6AA8 !important;
}

.trimble-search .sui-layout-sidebar .facet-list button.show-more-options:hover line,
.trimble-search .sui-layout-sidebar .facet-list button.show-more-options:hover circle {
  stroke: #0A5282 !important;
}

.trimble-search .sui-layout-sidebar .facet-list .disabled {
  margin: 2em 0;
}

.trimble-search .sui-layout-sidebar .facet-list .disabled .facet-title h4 {
  color: #9D9DA6 !important;
  margin: 0;
}

.trimble-search .sui-layout-sidebar .facet-list .disabled .facet-title:hover h4 {
  color: #797980 !important;
}

.trimble-search .sui-layout-sidebar .facet-list .disabled .small-text {
  font-weight: normal;
}

.trimble-search .sui-layout-sidebar .facet-list .disabled .facet-title line, 
.trimble-search .sui-layout-sidebar .facet-list .disabled .facet-title circle {
  stroke: #9D9DA6 !important;
}

.trimble-search .sui-layout-sidebar .facet-list .disabled .facet-title:hover line, 
.trimble-search .sui-layout-sidebar .facet-list .disabled .facet-title:hover circle {
  stroke: #797980 !important;
}

.trimble-search .sui-layout-sidebar .facet-list .sui-facet .options {
  margin: 8px 0;
  display: flex;
  flex-direction: column;
}

.trimble-search .sui-layout-sidebar .facet-list .sui-facet .options .option .facet-result-count {
  flex-grow: 1;
  text-align: right;
  font-weight: normal;
}

.trimble-search .sui-layout-sidebar .facet-list .sui-facet .options .option input[type="checkbox"],
.trimble-search .sui-layout-sidebar .facet-list .sui-facet .options .option .radio-button-control,
.trimble-search .sui-layout-sidebar .facet-list .sui-facet .options .option .checkbox-control {
  cursor: pointer;
}

.trimble-search .sui-layout-sidebar .facet-list .sui-facet .options .option .checkbox,
.trimble-search .sui-layout-sidebar .facet-list .sui-facet .options .option .radio {  
  display: flex !important;
  margin: 0 !important;
  align-items: center;
  font-size: 2rem;
  color: #363545;
}

.trimble-search .sui-layout-sidebar .facet-list .sui-facet .options .option .checkbox-input {
  display: -ms-grid;
  display: grid;
  grid-template-areas: "checkbox";
}

.trimble-search .sui-layout-sidebar .facet-list .sui-facet .options .option .checkbox-control,
.trimble-search .sui-layout-sidebar .facet-list .sui-facet .options .option .radio-button-control {
  background-color: #F9F9FB;
}

.trimble-search .sui-layout-sidebar .facet-list .sui-facet .options .option .checkbox-input,
.trimble-search .sui-layout-sidebar .facet-list .sui-facet .options .option .radio-button-input {
  margin-right: 0.5rem;
}

.trimble-search .sui-layout-sidebar .facet-list .sui-facet .options .option .checkbox-input input {
  opacity: 0;
  width: 1rem;
  height: 1rem;
  margin: 0 !important;
}

.trimble-search .sui-layout-sidebar .facet-list .sui-facet .options .option .checkbox-control {
  display: -ms-grid;
  display: grid;
  align-items: center;
  justify-content: center;
  width: 1rem;
  height: 1rem;
  border-radius: 0.05em;
  border: 0.0625rem solid currentColor;
}

.trimble-search .sui-layout-sidebar .facet-list .sui-facet .options .option .checkbox-input > * {
  grid-area: checkbox;
  display: flex;
} 

.trimble-search .sui-layout-sidebar .facet-list .sui-facet .options .option .checkbox-input input + span.checkbox-control::before {
  display: block; 
  content: "";
  width: 0.75rem;
  height: 0.75rem;
  box-shadow: inset 1em 1em #0D6AA8;
  transition: transform 10ms ease-in 50ms;
  transform: scale(0);    
}

.trimble-search .sui-layout-sidebar .facet-list .sui-facet .options .option .checkbox-input input:checked + .checkbox-control::before {
  transform: scale(1);
}


.trimble-search .sui-layout-sidebar .facet-list .sui-facet .options .option .radio-button-label,
.trimble-search .sui-layout-sidebar .facet-list .sui-facet .options .option .checkbox-label {
  cursor: pointer;
}

.trimble-search .sui-layout-sidebar .facet-list .sui-facet .options .option .radio-button {
  display: flex;
  align-items: center;
}

.trimble-search .sui-layout-sidebar .facet-list .sui-facet .options .option span.radio-button-label, 
.trimble-search .sui-layout-sidebar .facet-list .sui-facet .options .option span.checkbox-label {
  margin: 0.275rem;
  
}

.trimble-search .sui-layout-sidebar .facet-list .sui-facet .options .option .radio-button span.radio-button-input input {
  opacity: 0;
  width: 0;
  height: 0;
  display: none;
}

.trimble-search .sui-layout-sidebar .facet-list .sui-facet .options .option .radio-button span.radio-button-input input + span.radio-button-control::before {
  display: block; 
  content: "";
  width: 0.75rem;
  height: 0.75rem;
  box-shadow: inset 1em 1em #0D6AA8;
  border-radius: 50%;
  transition: 50ms transform ease-in-out;
  transform: scale(0);    
}

.trimble-search .sui-layout-sidebar .facet-list .sui-facet .options .option .radio-button span.radio-button-input input:checked + span.radio-button-control::before {
  transform: scale(1);
}

.trimble-search .sui-layout-sidebar .facet-list .sui-facet .options .option .radio-button span.radio-button-control {
  display: -ms-grid;
  display: grid;
  align-items: center;
  justify-items: center;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  border: 0.0625rem solid currentColor;
}

.trimble-search .mobile-sidebar-header {
  display: none;
}

/* RESPONSIVENESS */

@media (max-width: 975px) {
  .trimble-search .sui-layout-sidebar {
    width: 40% !important;
  }
}

@media (max-width: 800px) {
  .trimble-search .sui-layout-sidebar {
    width: 100% !important;
    position: relative !important;
  }

  .trimble-search .button-wrapper {
    margin: 0 1.5rem 0.5rem 1.5rem;
  }

  .trimble-search .button-wrapper .mobile-sidebar-header {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
    color: inherit;
  }

  .trimble-search .button-wrapper .mobile-sidebar-header > button.sui-layout-sidebar-toggle.close-sidebar {
    position: absolute;
    padding: 0.25em;
    right: 0;
    top: 0;
  }

  .trimble-search .button-wrapper .mobile-sidebar-header > h3 {
    margin: 0 !important;
    text-align: center;
  }
}