.trimble-search .sui-layout-main {
  padding: 0 1.5rem !important;
}

/* RESULTS - RESULTS HEADER */

.trimble-search .results-header {
  display: flex;
  flex-direction: column;
}

.trimble-search .results-header .paging-info > p {
  margin-top: 1em !important;
  margin-bottom: 0;
}

.trimble-search .results-header .filter-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-top: 1em;
}

.trimble-search .results-header .filter-list .filter {
  margin: 1em 1em 0 0;
  padding: 0 1em;
  border: none;
  background-color: #BFBFC6;
  display: flex;
  align-items: center;
  height: 2rem;
}

.trimble-search .results-header .filter-list .filter > .small-text {
  display: flex;
}

.trimble-search .results-header .filter-list .filter:hover {
  background-color: #0D5B8F;
}

.trimble-search .results-header .filter-list .filter:focus {
  background-color: #0A4065;
}

.trimble-search .results-header .filter-list .filter > .small-text {
  color: #363545;
  line-height: 2.5em !important;
}

.trimble-search .results-header .filter-list .filter:hover > .small-text, 
.trimble-search .results-header .filter-list .filter:focus > .small-text {
  color: #FFFFFF;
}

.trimble-search .results-header .filter-list .filter svg {
  margin-left: 0.5em;
  height: 1rem;
  cursor: pointer;
}

.trimble-search .results-header .filter-list .filter path {
  stroke: #363545;
  stroke-width: 3;
}

.trimble-search .results-header .filter-list .filter:hover path,
.trimble-search .results-header .filter-list .filter:focus path {
  stroke: #FFFFFF;
}

/* RESULTS - RESULTS LIST */

.trimble-search .result-list {
  margin-block-end: 1em;
  padding: 0;
}

.trimble-search .result-list .no-results-message {
  margin: 3rem 0 !important;
  font-weight: 600 !important;
}

.trimble-search .result-list .sui-result {
  display: flex;
  flex-direction: column;
  font-family: inherit !important;
  border: none !important;
  background: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  padding: 1.5rem 0 !important;
}

.trimble-search .result-list .sui-result .result-header {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.trimble-search .result-header .target-url {
  margin: 0rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.85rem !important;
}

.trimble-search .result-list .sui-result .result-header:hover svg * {
  stroke: #54526B;
}

.trimble-search .result-list .sui-result .result-body {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  line-height: 1.5;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  list-style: none;
}

.trimble-search .result-list .sui-result .result-body p {
  margin: 0;
}

.trimble-search .result-list .sui-result .result-body .result-teaser {
  flex: 2 auto;
  display: flex;
  min-width: 50%;
}

.trimble-search .result-list .sui-result .result-body .result-preview {
  text-align: center;
  flex: 1 auto;
  margin-top: 1.5rem;
  margin-left: 2.5625rem;
}

.trimble-search .result-list .sui-result .result-body .result-preview > img {
  max-height: 10.688rem;
  border: 1px solid #176BA6;
}

.trimble-search .result-list .sui-result .result-footer {
  margin-top: 0.5rem;
}

.trimble-search .result-list .sui-result .tag-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.trimble-search .result-list .sui-result .tag-list .result-tag {
  margin: 1em 1em 0 0;
  padding: 0 1em;
  border: 1px solid #176BA6;
  background-color: #F9F9FB;
  color: #0D6AA8;
  cursor: pointer;
  display: flex;
}

.trimble-search .result-list .sui-result .tag-list .result-tag:hover {
  background-color: #0D5B8F;
  color: #FFFFFF;
}

.trimble-search .result-list .sui-result .tag-list .result-tag:focus {
  background-color: #0A4065;
  color: #FFFFFF;
  outline: none;
}

/* RESULTS - RESULTS FOOTER */

.trimble-search .sui-layout-main-footer {
  margin-top: 1.5em;
  margin-bottom: 2.75rem;
}

.trimble-search .sui-layout-main-footer .sui-paging {
  display: flex;
  align-items: baseline;
}

.trimble-search .sui-layout-main-footer .sui-paging .rc-pagination-item {
  min-width: 1.5rem;
  height: 1.65rem;
}

.trimble-search .sui-layout-main-footer .sui-paging .rc-pagination-item:hover a {
  background-color: inherit !important;
  color: #54526B !important;
}

.trimble-search .sui-layout-main-footer .sui-paging .rc-pagination-jump-prev:after, 
.trimble-search .sui-layout-main-footer .sui-paging .rc-pagination-jump-next:after {
  display: inline !important;
  color: #363545 !important;
}

.trimble-search .sui-layout-main-footer .sui-paging li.rc-pagination-next svg,
.trimble-search .sui-layout-main-footer .sui-paging li.rc-pagination-prev svg {
  height: 0.70rem;
}

.trimble-search .sui-layout-main-footer .sui-paging li.rc-pagination-next svg *,
.trimble-search .sui-layout-main-footer .sui-paging li.rc-pagination-prev svg * {
  stroke-width: 3;
}

.trimble-search .sui-layout-main-footer .sui-paging .rc-pagination-item-active {
  border-radius: 0 !important;
  border-bottom: 2px solid #0A5282 !important;
  cursor: auto;
}

.trimble-search .sui-layout-main-footer .sui-paging .rc-pagination-item-active a {
  font-weight: inherit !important;
}

@media (max-width: 800px) {
  .trimble-search .result-list .sui-result .result-body {
    flex-direction: column;
  }
  
  .trimble-search .result-list .sui-result .result-body .result-preview > img {
    margin-left: 0;
    margin-top: 1.5rem;
  }
}

@media (max-width: 600px) {
  .trimble-search .result-list .sui-result .result-body {
    flex-direction: column;
  }
  
  .trimble-search .result-list .sui-result .result-body > div {
    width: 100%
  }

  .trimble-search .result-list .sui-result .result-body .result-preview img {
    max-height: unset;
    width: 100%;
  }
}